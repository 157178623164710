import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProvinceService } from '../services/province.service';
import { CreateProvince, edit } from '../Model/ProvinceModel';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-province',
  templateUrl: './province.component.html',
  styleUrls: ['./province.component.css']
})
export class ProvinceComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  CreateProvince: CreateProvince = new CreateProvince();
  currentSelected: any[];
  searchInput: any;
  departments: any;
  editmodel:edit=new edit()
  ProvinceData:Array<any>= new Array();
  isAdmin : boolean = false;
  role : any;


  constructor(private province: ProvinceService,private Toastr: ToastrService) { }

  ngOnInit()
   {
    this.CreateProvince.IsActive = true;
    this.headerTitle.emit("Division");
    this.role = localStorage.getItem("role");
    this.isAdmin = true;
    this.province.getAllProvince().subscribe(data=>{
      this.ProvinceData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });
   }


  create() {
    this.CreateProvince.ProvinceName=this.CreateProvince.ProvinceName.trim();
    this.province.AddNewProvince(this.CreateProvince).subscribe((resp) => {
      if(resp == 'Province already exist'){
        this.Toastr.error(resp);
      }else{
        this.Toastr.success(resp);
      }
      this.ngOnInit();
      this.currentSelected = [];
      this.CreateProvince.ProvinceName='';
      this.CreateProvince.ProvinceCode='';
      this.searchInput.nativeElement.value = '';
      this.departments.nativeElement.value = '';
      
    },
      (error) => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  Update(){
    this.province.UpdateProvince(this.editmodel).subscribe(resp=>{
      this.Toastr.success('Province Updated Successfully ');
      $("#editDistrictModal").modal("hide");
      this.ngOnInit();
    },err=>{
      // console.log(err);
    });

  } 

  SelectedProvinceDetail(data){
    this.editmodel = data;
  } 
}
