export class CreateDivision{
    DivisionId : any;
    IsActive : any;
    DivisionName : any;
    DivisionCode : any;
    ProvinceId: any;
}
export class edit{
    DivisionId : any;
    IsActive : any;
    DivisionName : any;
    DivisionCode : any;
    Code:any;
    ProvinceId: any;
}
export class filt{
    DivisionId : any;
    IsActive : any;
    DivisionName : any;
    DivisionCode : any;
    ProvinceId: any;
}