import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { THQDeptModel } from '../Model/THQDepartmentsModel';
import { ThqdepartmentService } from '../services/thqdepartment.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-thqdepartments',
  templateUrl: './thqdepartments.component.html',
  styleUrls: ['./thqdepartments.component.css']
})
export class THQDepartmentsComponent implements OnInit {

  @Output() headerTitle = new EventEmitter<string>();
  THQDeptData: Array<any>= new Array();
  selectedTHQDepartmentData: THQDeptModel = new THQDeptModel();
  Name: string;
  Code: string;
  IsActive: boolean = true;
  isAdmin : boolean = false;
  showselectedTHQDept: boolean = false;
  
  constructor(private ThqdepartmentsService: ThqdepartmentService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.IsActive = true;
    this.headerTitle.emit("THQ Departments");
    this.isAdmin = true;
    this.ThqdepartmentsService.AllTHQDepartments().subscribe(data=>{
      this.THQDeptData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }
  selectedTHQDept(data){
    this.selectedTHQDepartmentData = data;
    this.showselectedTHQDept = true;
  } 

  create(){
    console.log("create thq", this.Name, this.Code, this.IsActive)
    this.ThqdepartmentsService.AddNewTHQDepartment(this.Name,this.Code,this.IsActive).subscribe((resp)=>{
      this.Toastr.success('THQ Department Created Successfully ');
      this.ngOnInit();
      this.Name = '';
      this.Code = '';
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  Update(){
    this.ThqdepartmentsService.UpdateTHQDepartment(this.selectedTHQDepartmentData).subscribe(resp=>{
      this.Toastr.success('THQ Department Updated Successfully ');
      $("#EditDHqDeptModal").modal("hide");
      this.ngOnInit();
    },err=>{
    });
  } 

}
