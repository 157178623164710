import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GenericServiceService } from '../services/generic-service.service';
import { AllpatientsService } from '../services/allpatients.service';
import { DashboardServiceService } from '../services/dashboard-service.service';
import { Router } from '@angular/router';
import { ExportService } from '../services/export.service';
import { GetAllPatientsModel, GetPatientsForReferral, GetallpatientDataDefault } from '../Model/PatientModel';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PatientStatusService } from '../services/patient-status.service';
import { MulSheetExportService } from '../services/mul-sheet-export.service';
import { DivisionService } from '../services/division.service';
import { DivisionalHospitalService } from '../services/divisional-hospital.service';
import { RHCServiceService } from '../services/RHC-service.service';
import { THQService } from '../services/thq.service';
import { DHQServiceService } from '../services/DHQ-service.service';
import { DistrictModel } from '../Model/DistrictModel';
import { CreateDivision } from '../Model/DivisionModel';
import { DHQ } from '../Model/DHQModel';
import { ProvinceService } from '../services/province.service';
import { CreateProvince } from '../Model/ProvinceModel';
declare var $: any;

@Component({
  selector: 'app-allpatients',
  templateUrl: './allpatients.component.html',
  styleUrls: ['./allpatients.component.css']
})
export class AllpatientsComponent implements OnInit {
  public myModel = ''
  public mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /[A-Z]/]
  isAdmin: boolean = false;
  showPatientDetails: boolean = false;
  filter: boolean = null;
  PatientStatus: any
  p: boolean = null;
  showRHCsMultiDropDown: boolean = false
  @Output() headerTitle = new EventEmitter<string>();
  //getallpatientsmodel : PatientModel = new PatientModel();
  getallpatientsmodel: Array<GetAllPatientsModel> = new Array();
  patientModel: any[];
  exportDataPatients: any
  SelectedPatientDetails: GetAllPatientsModel = new GetAllPatientsModel();
  filterpat: GetallpatientDataDefault = new GetallpatientDataDefault();
  RHCData: Array<any> = new Array();
  DHDeptData: Array<any> = new Array();
  GenderData: Array<any> = new Array();
  AppointmentsDetails: Array<any> = new Array();
  patientstatusData: Array<any> = new Array();
  ShowMaskErrorMessge: boolean = false;
  MaskErrorMessage: string;
  InitialStartDate: Date = new Date("2020-01-01");
  InitialEndDate: Date = new Date;
  GenderDatas: any
  patient__Id: any
  FilterPatientsData: GetPatientsForReferral = new GetPatientsForReferral();
  DistrictData: Array<any> = new Array();
  districtdefModel: Array<any> = new Array();
  districtModel: Array<DistrictModel> = new Array();
  divisionModel: Array<CreateDivision> = new Array()
  divisiondefModel: Array<any> = new Array();
  dhqdefModel: Array<any> = new Array();
  dhqModel: Array<DHQ> = new Array();
  role: any
  DivisionData: any;
  DhqData: any;
  THQData: any;
  districtid:number=0
  isProvinceSelected: boolean = false;
  provinceModel: Array<CreateProvince> = new Array()
  ProvinceData: any;
  provincedefModel: Array<any> = new Array();

  constructor(private service: DivisionService,private Province:ProvinceService, private rhcservice: RHCServiceService, private thqservice: THQService, private dhqservice: DHQServiceService, private allpatientsService: AllpatientsService, private dh_service: DivisionalHospitalService, private genericService: GenericServiceService, public datepipe: DatePipe, private router: Router, private exportService: ExportService, private dashboardService: DashboardServiceService, private Toastr: ToastrService, private patientstatusService: PatientStatusService) { }

  ngOnInit() {
    this.showPatientDetails = false;
    // this.InitialStartDate.setMonth(this.InitialStartDate.getMonth() - 12);
    this.InitialStartDate;
    this.ShowMaskErrorMessge = false;
    this.headerTitle.emit("All Children");
    this.divisionModel = [];
    this.provinceModel = [];
    // Get District
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'DashboardUser' || localStorage.getItem("role") == 'SuperAdmin') {
      this.role = localStorage.getItem("role");
      this.isAdmin = true;
      this.getallpatientsmodel = [];
      // Get Region
      this.FilterPatientsData.StartDate = this.datepipe.transform(this.InitialStartDate, 'yyyy-MM-dd');
      this.FilterPatientsData.EndDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd');
      if (this.FilterPatientsData.StartDate <= this.FilterPatientsData.EndDate) {
        this.allpatientsService.getAllPatients(this.FilterPatientsData).subscribe(data => {
          this.getallpatientsmodel = data;
        }, error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
      }
      else if (this.FilterPatientsData.StartDate > this.FilterPatientsData.EndDate) {
        this.Toastr.error('Start date cannot be ahead of End Date');
      }
      this.dashboardService.getGenderData().subscribe(data => {
        this.GenderData = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
      this.dashboardService.getGenderData().subscribe(data => {
        this.GenderDatas = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
      this.genericService.getAllDistrictData().subscribe(data => {
        this.DistrictData = data;
        var ActiveDistrictModel = [];
        this.DistrictData.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveDistrictModel.push(value);
          }
        },
          this.DistrictsMultiData = ActiveDistrictModel
        );
      },
        error => {
          // console.log(error);
        });
    }

    this.Province.getAllProvinceData().subscribe(data => {
      this.provincedefModel = data;
      var ActiveProvinceModel = [];
      this.provincedefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveProvinceModel.push(value);
        }
      },
        this.ProvinceMultiData = ActiveProvinceModel

      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });

    this.patientstatusService.getPatientStatusData().subscribe(data => {
      this.patientstatusData = data.filter(item => item.IsActive == true);
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
    this.dashboardService.getGenderData().subscribe(data => {
      this.GenderData = data;
      this.GendersMultiData = this.GenderData;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });

    this.dh_service.AllDHDepartments().subscribe(data => {
      this.DHDeptData = data;
      this.DHDepartmentMultiData = this.DHDeptData;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
    // this.service.getAllDivisionData().subscribe(data => {
    //   this.divisiondefModel = data;
    //   var ActiveDivisionModel = [];
    //   this.divisiondefModel.forEach(function (value) {
    //     if (value.IsActive == true) {
    //       ActiveDivisionModel.push(value);
    //     }
    //   },
    //     this.DivisionMultiData = ActiveDivisionModel
    //   );
    // },
    //   error => {
    //     this.Toastr.error("Something went on the server side !!!");
    //     // console.log(error);
    //   });
    this.showDHQ(this.districtid);
    this.showTHQ(this.districtid);
    this.showRHC(this.districtid);
  }

  showSelectedProvince(selectedValue: any) {
    if (!selectedValue.value || selectedValue.value.length === 0) {
        this.isProvinceSelected = false;
        this.DivisionMultiData = [];
        return;
    }

    // Province is selected; fetch related divisions
    this.isProvinceSelected = true;
    this.genericService.geDivisionDataByMultiProvince(selectedValue.value).subscribe(
        data => {
            this.divisiondefModel = data;

            // Filter active divisions for display
            this.DivisionMultiData = this.divisiondefModel.filter(division => division.IsActive);
        },
        error => {
            this.Toastr.error("Something went wrong on the server side !!!");
        }
    );
}

  showSelectedDivision(selectedValue: any) {
    if (!selectedValue.value || selectedValue.value.length === 0) {
      return;
    }
    this.genericService.getDistrictDataByMultiDivision(selectedValue.value).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.DistrictsMultiData = ActiveDistrictModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  handleChange(districtid: any) {
    if (districtid.value.length === 0) {
      this.districtid = 0;
  }else{ this.districtid = districtid.value}
    this.showDHQ(this.districtid);
    this.showTHQ(this.districtid);
    this.showRHC(this.districtid);
  }
  showDHQ(districtid:any) {
    // this.DhqMultiData=[]
    this.dhqservice.getAllDHQ(districtid).subscribe(data => {
      this.dhqdefModel = data;
      var ActiveDHQModel = [];
      this.dhqdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDHQModel.push(value);
        }
      },
        this.DhqMultiData = ActiveDHQModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  showRHC(districtid:any) {
    this.rhcservice.getAllRHC(districtid).subscribe(data => {
      this.dhqdefModel = data;
      var ActiveDHQModel = [];
      this.dhqdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDHQModel.push(value);
        }
      },
        this.RHCMultiData = ActiveDHQModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  showTHQ(districtid:any) {
    this.thqservice.getAllTHQ(districtid).subscribe(data => {
      this.dhqdefModel = data;
      var ActiveDHQModel = [];
      this.dhqdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDHQModel.push(value);
        }
      },
        this.THQMultiData = ActiveDHQModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  ProvinceMultiData: { [key: string]: Object; }[] = [];
  public ProvinceFields: Object = { text: 'ProvinceName', value: 'Id' };
  public ProvincePlaceholder: string = 'Select Province';

  DivisionMultiData: { [key: string]: Object; }[] = [];
  public DivisionFields: Object = { text: 'DivisionName', value: 'Id' };
  public DivisionPlaceholder: string = 'Select Division';

  DhqMultiData: { [key: string]: Object; }[] = [];
  public DhqFields: Object = { text: 'Name', value: 'Id' };
  public DhqPlaceholder: string = 'Select DHQ';

  DistrictsMultiData: { [key: string]: Object; }[] = [];
  public DistrictFields: Object = { text: 'DistrictName', value: 'Id' };
  public DistrictsPlaceholder: string = 'Select Districts';

  RHCMultiData: { [key: string]: Object; }[] = [];
  public RHCFields: Object = { text: 'Name', value: 'Id' };
  public RHCPlaceholder: string = 'All RHC';

  THQMultiData: { [key: string]: Object; }[] = [];
  public THQFields: Object = { text: 'Name', value: 'Id' };
  public THQPlaceholder: string = 'All THQ';

  DHDepartmentMultiData: { [key: string]: Object; }[] = [];
  public DHDepartmentFields: Object = { text: 'DepartmentName', value: 'Id' };
  public DHDepartmentPlaceholder: string = 'All DH Departments';

  GendersMultiData: { [key: string]: Object; }[] = [];
  public GendersFields: Object = { text: 'Gender', value: 'Id' };
  public GendersPlaceholder: string = 'All Genders';

  SelectedDistrict(form) {
    //  this.mulObj.value = [];
    this.filterpat.DistrictId = form.value.DistrictData.toString();
    this.dashboardService.getAllRHC(this.filterpat.DistrictId).subscribe(data => {
      this.RHCData = data;
      this.RHCMultiData = this.RHCData;
      this.showRHCsMultiDropDown = true;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  SelectedPs(form) {
    this.filterpat.PatientStatus = parseInt(form);
    this.PatientStatus = this.filterpat.PatientStatus
    //  console.log(this.PatientStatus);

  }
  onSubmit(form: any): void {
    this.filterpat.RHCIds = '0';
    this.filterpat.PatientStatus = '0';

    this.filterpat.DHDepIds = '0';
    this.filterpat.DistrictId = '0';

    this.filterpat.DHQIds = '0';
    this.filterpat.DivisionIds = '0';
    this.filterpat.ProvinceIds = '0';
    this.filterpat.THQIds = '0';


    this.filterpat.GenderIds = '0';
    // console.log(form.value.name);
    // this.filterpat.DistrictId = form.value.DistrictData.toString();
    if (form.value.RHCData !== undefined) {
      if (form.value.RHCData.length !== 0) {
        this.filterpat.RHCIds = form.value.RHCData.toString();
      }
    }
    if (form.value.DistrictData !== undefined) {
      if (form.value.DistrictData.length !== 0) {

        this.filterpat.DistrictId = form.value.DistrictData.toString();

      }
    }
    if (form.value.DHDeptData !== undefined) {
      if (form.value.DHDeptData.length !== 0) {
        this.filterpat.DHDepIds = form.value.DHDeptData.toString();
      }
    }
    if (form.value.GendersData !== undefined) {
      if (form.value.GendersData.length !== 0) {
        this.filterpat.GenderIds = form.value.GendersData.toString();
      }
    }
    if (form.value.DhqData !== undefined) {
      if (form.value.DhqData.length !== 0) {
        this.filterpat.DHQIds = form.value.DhqData.toString();
      }
    }
    if (form.value.RHCData !== undefined) {
      if (form.value.RHCData.length !== 0) {
        this.filterpat.RHCIds = form.value.RHCData.toString();
      }
    }
    if (form.value.THQData !== undefined) {
      if (form.value.THQData.length !== 0) {
        this.filterpat.THQIds = form.value.THQData.toString();
      }
    }
    if (form.value.DivisionData !== undefined) {
      if (form.value.DivisionData.length !== 0) {
        this.filterpat.DivisionIds = form.value.DivisionData.toString();
      }
    }
    if (form.value.ProvinceData !== undefined) {
      if (form.value.ProvinceData.length !== 0) {
        this.filterpat.ProvinceIds = form.value.ProvinceData.toString();
      }
    }

    this.filterpat.StartDate = this.datepipe.transform(this.InitialStartDate, 'yyyy-MM-dd');
    this.filterpat.EndDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd');
    if (this.filterpat.StartDate <= this.filterpat.EndDate) {
      // this.filterpat.PatientStatus = parseInt(data);
      this.filterpat.PatientStatus = this.PatientStatus
      console.log(this.filterpat)
      this.allpatientsService.getAllPatients(this.filterpat).subscribe(data => {

        this.getallpatientsmodel = data;
        $("#PatientsFilterModal").modal("hide");
      }, error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
      this.allpatientsService.exportPatients(this.filterpat).subscribe(data => {
        this.exportDataPatients = data;
        // console.log(this.exportDataPatients)    
      }, error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
    }
    else if (this.filterpat.StartDate > this.filterpat.EndDate) {
      this.Toastr.error('Start date cannot be ahead of End Date');
    }
  }

  selectedPatient(patient: GetAllPatientsModel) {
    this.SelectedPatientDetails = patient;
    this.SelectedPatientDetails.DOB = this.datepipe.transform(this.SelectedPatientDetails.DOB, 'yyyy-MM-dd');
    this.showPatientDetails = true;
  }

  getFirstNonNullName(): string {
    let name = this.SelectedPatientDetails.DHQName || this.SelectedPatientDetails.THQName || this.SelectedPatientDetails.RhcName || 'No Name Available';
    console.log(name)
    return name
  }

  selectedPatienttoEdit(patient: GetAllPatientsModel) {
    this.SelectedPatientDetails = patient;
    this.SelectedPatientDetails.DOB = this.datepipe.transform(this.SelectedPatientDetails.DOB, 'yyyy-MM-dd');
  }

  SelectedGender(data) {
    this.SelectedPatientDetails.GenderId = parseInt(data);
  }

  UpdatePatient() {
    this.SelectedPatientDetails.NCDRegistrationNumber = this.SelectedPatientDetails.NCDRegistrationNumber.replace(/[/_/]/g, '');
    if (this.SelectedPatientDetails.NCDRegistrationNumber.length == 9) {
      this.ShowMaskErrorMessge = false;
      this.allpatientsService.Update(this.SelectedPatientDetails).subscribe(resp => {
        this.Toastr.success('Patient Updated Successfully ');
        $("#EditPatient").modal("hide");
        this.ngOnInit();
        // console.log(resp);
      }, err => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(err);
      });
    }
    else {
      this.ShowMaskErrorMessge = true;
      this.MaskErrorMessage = '*Please Write the Patient NCD According to Format [DD-DDDD-A]. For example:- (01-0001-A)*';
    }
  }

  SelectedReferral(data) {
    this.AppointmentsDetails = data.Appointments;
  }

  selectedPatienttoDelete(data) {
    // console.log(data.PatientId);
    this.patient__Id = data.PatientId
  }
  DeletePatient() {
    this.genericService.removePatient(this.patient__Id).subscribe(res => {

      $("#delete___Patient").modal("hide");
      this.Toastr.success('Successfully Deleted');
      // this.ngOnInit();

      this.allpatientsService.getAllPatients(this.filterpat).subscribe(data => {
        this.getallpatientsmodel = data;
        $("#PatientsFilterModal").modal("hide");
      }, error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
    }, err => {
      this.Toastr.error('Error 302');
    });
  }
  export(reportType: number) {
    if (reportType == 1) {
      this.exportService.exportExcel(this.exportDataPatients, 'AllPatientsReport');
    }
  }
}
