import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DistrictsService } from '../services/districts.service';
import { edit, DistrictModel } from '../Model/DistrictModel';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DivisionService } from '../services/division.service';
import { UserModel, UserViewModel, editUser } from '../Model/DBUserModel';
import { GenericServiceService } from '../services/generic-service.service';
import { DashboardServiceService } from '../services/dashboard-service.service';
import { ProvinceService } from '../services/province.service';
declare var $: any;

@Component({
  selector: 'app-dashboard-users',
  templateUrl: './dashboard-users.component.html',
  styleUrls: ['./dashboard-users.component.css']
})
export class DashboardUsersComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  isAdmin: boolean = false;
  UserCreate: UserModel = new UserModel();
  UserViewModel: UserViewModel = new UserViewModel();
  districtdefModel: Array<any> = new Array();
  selectedDivisionId: number = 0;
  editmodel: editUser = new editUser();
  userModel: Array<UserModel> = new Array();
  showProvinceDropDown = false;
  showDivisionDropDown = false;
  showDropDowndistrict = false;
  private closeProvinceDropdownTimer: any;
  private closeDivisionDropdownTimer: any;
  private closeDropdownTimerdistrict: any;
  checkedList: any[] = [];
  currentSelected: any[] = [];
  checkedListdistrict: any[] = [];
  currentSelecteddistrict: any[] = [];
  ProvinceData: any;
  DivisionData: Array<any> = new Array(); 
  DistrictData: Array<any> = new Array();
  isProvinceSelected: boolean = false; 
  provincedefModel: Array<any> = new Array();
  divisiondefModel: Array<any> = new Array();
  showDivisionDropdown: false;
  showDistrictDropdown: false;
  
  @ViewChild('divisions') divisions: ElementRef;
  @ViewChild('districts') districts: ElementRef;

  constructor(
    private DistrictsService: DistrictsService,
    private dashboard_service: DashboardServiceService,
    private genericService: GenericServiceService,
    private router: Router,
    public datepipe: DatePipe,
    private Toastr: ToastrService,
    private DivisionService: DivisionService,
    private Province: ProvinceService
  ) { }

  ngOnInit() {
    this.UserCreate.IsActive = true;
    this.headerTitle.emit("Dashboard Users");
    this.isAdmin = true;
    this.DistrictData = [];
    this.DivisionData = [];
    this.ProvinceData = [];
    this.dashboard_service.getAllDashboardUsers().subscribe(data => {
      this.userModel = data;
    });
    this.Province.getAllProvinceData().subscribe(data => {
      this.provincedefModel = data;
      var ActiveProvinceModel = [];
      this.provincedefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveProvinceModel.push(value);
        }
      },
        this.ProvinceMultiData = ActiveProvinceModel

      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  toggleDropdown(item: any, dropdownType: string): void {
    if (dropdownType === 'divisionDropdown') {
      item.showDivisionDropdown = !item.showDivisionDropdown;
      item.showDistrictDropdown = false; // Close other dropdown if open
    } else if (dropdownType === 'districtDropdown') {
      item.showDistrictDropdown = !item.showDistrictDropdown;
      item.showDivisionDropdown = false; // Close other dropdown if open
    }
  }

  create() {
    console.log("Before API Call - ProvinceId: ", this.UserCreate.ProvinceId);
    console.log("Before API Call - DivisionId: ", this.UserCreate.DivisionId);
    console.log("Before API Call - DistrictId: ", this.UserCreate.DistrictId);
    this.dashboard_service.CreateDashboardUser(this.UserCreate).subscribe((resp) => {
      if (resp == 'this user already exist') {
        this.Toastr.error(resp);
      } else {
        this.Toastr.success(resp);
      }
      this.ngOnInit();
      this.UserCreate.Name = '';
      this.UserCreate.Mobile = '';
      this.UserCreate.Email = '';
    },
      (error) => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  showSelectedProvince(selectedValue: any) {
    if (!selectedValue.value || selectedValue.value.length === 0) {
        this.isProvinceSelected = false;
        this.DivisionMultiData = [];
        return;
    }

    // Province is selected; fetch related divisions
    this.isProvinceSelected = true;
    this.UserCreate.ProvinceId = selectedValue.value;
    this.genericService.geDivisionDataByMultiProvince(selectedValue.value).subscribe(
        data => {
            this.divisiondefModel = data;

            // Filter active divisions for display
            this.DivisionMultiData = this.divisiondefModel.filter(division => division.IsActive);
        },
        error => {
            this.Toastr.error("Something went wrong on the server side !!!");
        }
    );
}

  showSelectedDivision(selectedValue: any) {
    if (!selectedValue.value || selectedValue.value.length === 0) {
        this.DistrictsMultiData = []; // Clear districts if no division is selected
        return;
    }
    
    this.genericService.getDistrictDataByMultiDivision(selectedValue.value).subscribe(
        data => {
            this.districtdefModel = data;

            // Filter active districts
            this.DistrictsMultiData = this.districtdefModel.filter(district => district.IsActive);
        },
        error => {
            this.Toastr.error("Something went wrong on the server side !!!");
        }
    );
}

  SelectedUserDetail(data) {
    this.editmodel = data;
  }

  Update() {
    this.dashboard_service.UpdateDashboardUser(this.editmodel).subscribe(resp => {
      this.Toastr.success('User Updated Successfully ');
      $("#editDistrictModal").modal("hide");
      this.ngOnInit();
      this.editmodel.Name = '';
      this.editmodel.Mobile = '';
      this.editmodel.Email = '';
      this.editmodel.ProvinceId='';
    }, err => {
      // console.log(err);
    });
  }

  selectedUser(user: UserViewModel) {
    this.UserViewModel = user;
  }

  getSelectedValue(status: boolean, name: string, id: any) {
    const idIndex = this.checkedList.indexOf(id);
    if (status) {
        if (idIndex === -1) {
            this.checkedList.push(id);
            this.currentSelected.push(name);
        }
    } else {
        if (idIndex !== -1) {
            this.checkedList.splice(idIndex, 1);
            this.currentSelected.splice(this.currentSelected.indexOf(name), 1);
            this.clearDistrictsForDivision(id);  // Clear districts
        }
    }
    this.UserCreate.DivisionId = this.checkedList;  // Update DivisionId here
}

  clearDistrictsForDivision(divisionId: number) {
    this.districtdefModel = this.districtdefModel.filter(district => {
      if (district.DivisionId === divisionId) {
        const districtIndex = this.checkedListdistrict.indexOf(district.Id);
        if (districtIndex !== -1) {
          this.checkedListdistrict.splice(districtIndex, 1);
          this.currentSelecteddistrict.splice(this.currentSelecteddistrict.indexOf(district.DistrictName), 1);
        }
        return false;
      }
      return true;
    });
  }

  getSelectedValuedistrict(status: boolean, name: string, id: any) {
    const idIndex = this.checkedListdistrict.indexOf(id);
    if (status) {
        if (idIndex === -1) {
            this.checkedListdistrict.push(id);
            this.currentSelecteddistrict.push(name);
        }
    } else {
        if (idIndex !== -1) {
            this.checkedListdistrict.splice(idIndex, 1);
            this.currentSelecteddistrict.splice(this.currentSelecteddistrict.indexOf(name), 1);
        }
    }
    this.UserCreate.DistrictId = this.checkedListdistrict;  // Update DistrictId here
}

  startDropdownCloseTimer(dropdownType: string) {
    switch (dropdownType) {
      case 'province':
        this.closeProvinceDropdownTimer = setTimeout(() => {
          this.showProvinceDropDown = false;
        }, 1500);
        break;
  
      case 'division':
        this.closeDivisionDropdownTimer = setTimeout(() => {
          this.showDivisionDropDown = false;
        }, 1500);
        break;
  
      case 'district':
        this.closeDropdownTimerdistrict = setTimeout(() => {
          this.showDropDowndistrict = false;
        }, 1500);
        break;
  
      default:
        break;
    }
  }
  
  cancelDropdownCloseTimer(dropdownType: string) {
    switch (dropdownType) {
      case 'province':
        clearTimeout(this.closeProvinceDropdownTimer);
        break;
  
      case 'division':
        clearTimeout(this.closeDivisionDropdownTimer);
        break;
  
      case 'district':
        clearTimeout(this.closeDropdownTimerdistrict);
        break;
  
      default:
        break;
    }
  }
  
  ProvinceMultiData: { [key: string]: Object; }[] = [];
  public ProvinceFields: Object = { text: 'ProvinceName', value: 'Id' };
  public ProvincePlaceholder: string = 'Select Province';

  DivisionMultiData: { [key: string]: Object; }[] = [];
  public DivisionFields: Object = { text: 'DivisionName', value: 'Id' };
  public DivisionPlaceholder: string = 'Select Division';

  DistrictsMultiData: { [key: string]: Object; }[] = [];
  public DistrictFields: Object = { text: 'DistrictName', value: 'Id' };
  public DistrictsPlaceholder: string = 'Select Districts';
}
